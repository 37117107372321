import { Routes } from "../Routes/Routes";

export const BreadCrumbRedirection = {
    "/settings": Routes.User.Settings.Profile.ProfileSettings,
    "/home/dashboard": Routes.User.Dashboard,
    "/role-permission": "/settings/role-permission",
    "/survey": "/survey",
    "/checklist": "/checklist",
    "/product-tour": "/product-tour",
    "/app/applications/features": (appid) => `/app/applications/${appid}/features`,
    "/app/applications/features/subfeatures": (appid, fid) =>
        `/app/applications/${appid}/features/${fid}/subfeatures`,
};

export const BreadCrumbAliases = {
    "demo-x": "demoX",
    app: "Apps",
    applications: "Applications",
    features: "Features",
    subfeatures: "Sub Features",
    tempcategory: "Email Category",
    emailtemplates: "Email Templates",
    systemcategory: "System Category",
    "type-product-tour": "Product Tour Type",
    "templates-product-tour": "Product Tour Templates",
    mausrange: "MAUs Range",
    "sms-services": "SMS Services",
};
